gsap.config({
    nullTargetWarn: false,
    trialWarn: false,
})

import {
    gsap,
    Power0,
    Power1,
    Power2,
    Power3,
    Power4,
} from "gsap"

const romboid = document.getElementById('coverRomboidImg')
const logoLrg = document.getElementById('logoLargeImg')
const maraImg = document.getElementById('background')

function indexEnter() {
    const tl = gsap.timeline()

    tl.set("li", {
            x: -200,
            opacity: 0
        })

        .fromTo(maraImg, {
            opacity: 0,
            scale: 1.5
        }, {
            opacity: 1,
            scale: 1,
            zIndex: -1,
            ease: Power1.easeOut,
            duration: 2
        })

        .fromTo(logoLrg, {
            opacity: 0,
            scale: 0
        }, {
            opacity: 1,
            scale: 1,
            ease: Power2.easeOut,
            duration: 2
        })

        .to(romboid, {
            opacity: 0.56,
            zIndex: 0,
            ease: Power2.easeOut,
            duration: 2,
            delay: 2
        })

        .to("li", {
            x: 0,
            opacity: 1,
            stagger: 0.2,
            ease: Power3.easeOut,
            duration: 2
        })
}

export default indexEnter