gsap.config({
    nullTargetWarn: false,
    trialWarn: false,
})

import {
    gsap,
    Power0,
    Power1,
    Power2,
    Power3,
    Power4,
    Expo
} from "gsap"

const square = document.getElementById('coverSquareImg')
const logoSml = document.getElementById('logoSmallImg')
const header = document.getElementById('aboutHeader1')
const paragraph = document.getElementById('aboutParagraph1')
const maraImg = document.getElementById('background')

function aboutEnter() {
    var tl = gsap.timeline()

    tl.set(maraImg, {
        scale: 1.6,
        x: -25,
        y: 210,
    })

    tl.set(square, {
        opacity: 0
    })

    tl.set(paragraph, {
        opacity: 0,
        y: 800
    })

    tl.to(square, 2, {
        opacity: 0.56
    })

    tl.fromTo(logoSml, 2, {
        opacity: 0,
        scale: 0.8
    }, {
        opacity: 1,
        scale: 1,
        ease: Power0.easeOut
    }, "-=2")

    tl.fromTo(header, 2, {
        opacity: 0,
        y: 200
    }, {
        opacity: 1,
        y: 0,
        ease: Expo.easeOut
    }, "-=1")

    tl.to(paragraph, 2, {
        opacity: 1,
        y: 0,
        ease: Expo.easeOut
    }, "-=2")
}

export default aboutEnter