gsap.config({
    nullTargetWarn: false,
    trialWarn: false,
})

import {
    gsap,
    Power0,
    Power1,
    Power2,
    Power3,
    Power4,
    Circ
} from "gsap"

const romboid = document.getElementById('coverRomboidImg')
const logoLrg = document.getElementById('logoLargeImg')
const maraImg = document.getElementById('background')

function indexLeave() {
    const tl = gsap.timeline()

    tl.to(maraImg, {
        scale: 1.6,
        x: -25,
        y: 210,
        duration: 2,
        ease: Circ.easeOut
    })

    tl.to(romboid, {
        scale: 4.5,
        opacity: 0,
        duration: 2,
        ease: Power2.easeOut
    }, "-=2")

    tl.to(logoLrg, {
        opacity: 0,
        duration: 1.5,
        ease: Power0.easeOut
    }, "-=2")
}

export default indexLeave